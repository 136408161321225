/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidateMembershipRequest } from '../models/ValidateMembershipRequest';
import type { ValidateMembershipResponse } from '../models/ValidateMembershipResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NgbIntegrationsService {

    /**
     * Validates membership for a specific NGB integration
     * @param ngbIntegrationId
     * @param requestBody
     * @returns ValidateMembershipResponse Success
     * @throws ApiError
     */
    public static validateMembership(
        ngbIntegrationId: number,
        requestBody?: ValidateMembershipRequest,
    ): CancelablePromise<ValidateMembershipResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ngb-integrations/{ngbIntegrationId}/validate-membership',
            path: {
                'ngbIntegrationId': ngbIntegrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
