import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Cell, Grid, Loader } from '@teamsnap/teamsnap-ui';

import { ActionContainer } from 'components/shared';
import { useAppDispatch } from 'state/hooks';
import {
  loadCapacityCounts,
  loadConfirmationMessage,
  loadForm,
  useFormCapacityDetailsLoadedSelector,
  useFormSelector,
  useFormStateSelector,
} from 'state/form/formSlice';
import { loadUser, useUserStateSelector } from 'state/user/userSlice';
import {
  loadRegistrations,
  loadRegistrationsWithDiscounts,
  useRegistrationsLoadedSelector,
} from 'state/registration/registrationSlice';
import { VerifyMembershipPage } from 'pages/Form/VerifyMembershipPage';
import SelectParticipantPage from 'pages/Form/selectParticipant/SelectParticipantPage';
import { AnswerQuestionsPage } from 'pages/Household/AnswerQuestionsPage';
import { OverviewPage } from './Form';
import { NotFound } from './NotFound';
import { Email, Login, NewUser } from './User';
import { GroupsPage } from './Form/GroupsPage';
import { CartPage } from './Form/CartPage';
import { WaiverPage } from './Form/WaiverPage';
import { PaymentPage } from './Form/PaymentPage';
import { loadHouseholdsAndPeople } from 'state/households/householdSlice';
import { ResetPassword } from './User/ResetPassword';
import { FeatureFlagService } from 'frontend-toolkit';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import ENVIRONMENT from 'core/environment';
import { discountsFromLocalStorage } from 'components/Form/Discounts/DiscountStorage';
import { useAmplitude } from 'state/hooks/useAmplitude';

const PATHS_TO_CHECK_FOR_CAPACITY_LIMITS = ['cart'];

export const Root = () => {
  const { formId, path } = useParams<{ formId: string; path: string }>();
  const dispatch = useAppDispatch();
  const formState = useFormStateSelector();
  const userState = useUserStateSelector();
  const form = useFormSelector();
  const capacityLoaded = useFormCapacityDetailsLoadedSelector();
  const registrationsLoaded = useRegistrationsLoadedSelector();
  const { amplitudeIdentify, amplitudeTrack } = useAmplitude();

  const { organizationId } = form || {};

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId
  );

  useEffect(() => {
    if (!userState.data?.user) {
      dispatch(loadUser());
    }

    dispatch(loadForm(parseInt(formId || '0')));

    if (userState.data?.user) {
      dispatch(loadConfirmationMessage(parseInt(formId || '0')));
      dispatch(loadRegistrations());
      dispatch(loadHouseholdsAndPeople());

      const { uciUuid, email } = userState.data?.user || {};
      if (uciUuid) {
        amplitudeIdentify(uciUuid, email);
      }
    }
  }, [userState.data?.user]);

  useEffect(() => {
    if (capacityLimitsAreEnabled && registrationsLoaded && formId) {
      if (
        !capacityLoaded ||
        (path && PATHS_TO_CHECK_FOR_CAPACITY_LIMITS.includes(path))
      ) {
        dispatch(loadCapacityCounts(+formId));
      }
    }

    if (formId) {
      const couponCodes = discountsFromLocalStorage(+formId, null);
      dispatch(
        loadRegistrationsWithDiscounts({
          formId: +formId,
          couponCodes:
            couponCodes.length > 0
              ? couponCodes.filter((x) => x).join(',')
              : '',
        })
      );
    }
  }, [formId, path, capacityLimitsAreEnabled, registrationsLoaded]);

  useEffect(() => {
    if (userState.data?.userLoadCalled) {
      amplitudeTrack.screenViewed(
        path,
        !!userState.data?.user,
        userState.data?.loginEmail,
        formId
      );
    }
  }, [path, userState.data?.userLoadCalled]);

  if (
    formState.processing ||
    !userState.data?.userLoadCalled ||
    (!registrationsLoaded && userState.data?.user)
  ) {
    return (
      <ActionContainer title="Registration form">
        <Grid isWithGutter>
          <Cell mods="u-flex u-flexJustifyCenter u-spaceBottomMd">
            <Loader type="spin" text="Loading..." />
          </Cell>
        </Grid>
      </ActionContainer>
    );
  }

  if (!formState.data) {
    return <NotFound />;
  }

  if (userState.data?.user) {
    switch (path) {
      case 'selectParticipant':
        return <SelectParticipantPage />;
      case 'verifyMembership':
        return <VerifyMembershipPage />;
      case 'selectGroup':
        return <GroupsPage />;
      case 'cart':
        return <CartPage />;
      case 'agreements':
        return <WaiverPage />;
      case 'answerQuestions':
        return <AnswerQuestionsPage />;
      case 'payment':
        return <PaymentPage />;
      default:
        return <OverviewPage />;
    }
  } else {
    switch (path) {
      case 'login':
        return userState.data?.loginEmail ? <Login /> : <Email />;
      case 'newUser':
        return userState.data?.loginEmail ? <NewUser /> : <Email />;
      case 'reset':
        return <ResetPassword />;
      default:
        return <Email />;
    }
  }
};
