import * as React from 'react';
import { Icon, LabelButton, Tooltip, TooltipProvider } from '@teamsnap/snap-ui';

// Local imports
import {
  ActionContainer,
  AlphaNumericInput,
  CartButton,
} from 'components/shared';
import { Header } from '../Header/Header';
import { useUserSelector } from 'state/user/userSlice';
import { useFormSelector } from 'state/form/formSlice';
import { useCurrentMemberSelector } from 'state/registration/registrationSlice';
import { useAppNavigate } from 'state/hooks';
import { NgbIntegrationData } from 'pages/Form/VerifyMembershipPage';
import { ManageHouseholdMember } from 'pages/Form/selectParticipant/_sections/ManageHouseholdMember/ManageHouseholdMember';
import HouseholdMemberCard from 'pages/Form/selectParticipant/_sections/HouseholdMemberCard';
import {
  useHouseholdPeopleSelector,
  usePrimaryHouseholdIdSelector,
} from 'state/households/householdSlice';
import { HouseholdPerson, NgbIntegrationsService } from 'core/api';
import usaHockeyLogoImage from '../../../assets/images/usa-hockey.svg';
import usaLacrosseLogoImage from '../../../assets/images/usa-lacrosse.svg';

import { Button, Loader } from '@teamsnap/teamsnap-ui';
import { NGB_INTEGRATION_CODES } from 'core/constants';

interface Props {
  ngbIntegration: NgbIntegrationData;
  seasonStartDate: string | null;
  seasonEndDate: string | null;
}

export const VerifyMembership = ({
  ngbIntegration,
  seasonStartDate,
  seasonEndDate,
}: Props) => {
  const { metadata, id, code } = ngbIntegration;
  const { fields } = metadata;
  const membershipNumberField = fields[0];
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [membershipNumberValue, setMembershipNumberValue] =
    React.useState<string>('');
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [selectedHouseholdPerson, setSelectedHouseholdPerson] = React.useState<
    HouseholdPerson | undefined
  >(undefined);
  const [error, setError] = React.useState<string>('');

  const navigate = useAppNavigate();
  const primaryHouseholdId = usePrimaryHouseholdIdSelector();
  const householdPople = useHouseholdPeopleSelector();
  const user = useUserSelector();
  const currentMember = useCurrentMemberSelector();
  const currentForm = useFormSelector();
  const { organizationName } = currentForm || {};

  React.useEffect(() => {
    const hp = householdPople?.find((hp) => hp.id === currentMember?.id);
    setSelectedHouseholdPerson(hp);
  }, [householdPople, currentMember]);

  const handleOnParticipantEdit = () => {
    setIsEditOpen(true);
  };

  const openMembershipLink = () => {
    window.open(metadata.get_membership_link);
  };

  const verify = async () => {
    setIsSubmitting(true);

    const body = {
      membershipNumber: membershipNumberValue || '',
      firstName: selectedHouseholdPerson?.person.firstName || '',
      lastName: selectedHouseholdPerson?.person.lastName || '',
      birthdate: selectedHouseholdPerson?.person.birthdate || '',
      seasonStartDate: seasonStartDate || undefined,
      seasonEndDate: seasonEndDate || '',
    };

    NgbIntegrationsService.validateMembership(id, body)
      .then((validateResponse) => {
        if (validateResponse?.valid) {
          setError('');
          setIsVerified(true);
          setIsSubmitting(false);
          setTimeout(() => {
            navigate(`/form/${currentForm?.id}/selectGroup`);
          }, 2500);
        }
      })
      .catch((e) => {
        console.error(e);
        const error = e.body?.detail ? e.body?.detail : e.body;
        setError(error);
        setIsVerified(false);
        setIsSubmitting(false);
      });
  };

  const getLogoImage = () => {
    switch (code) {
      case NGB_INTEGRATION_CODES.usaHockey:
        return usaHockeyLogoImage;
      case NGB_INTEGRATION_CODES.usaLacrosse:
        return usaLacrosseLogoImage;
    }
  };

  return (
    <>
      <div className="verify-membership">
        <ActionContainer
          header={
            <Header
              title={organizationName ?? 'Verify membership'}
              rightIcon={<CartButton />}
              profileName={`${user?.firstName} ${user?.lastName}`}
            />
          }
          footer={
            <div className="t:sui-flex t:items-center t:sui-justify-between">
              <div>
                <Button
                  mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
                  onClick={() =>
                    navigate(`/form/${currentForm?.id}/selectParticipant`)
                  }
                  icon="arrow-left"
                  iconPosition="left"
                  testId="action-container--back-button"
                >
                  Back
                </Button>
              </div>
              {isVerified ? (
                <Button
                  color="primary"
                  mods="sui-bg-positive-background-strong sui-my-1 t:sui-w-auto sui-px-8 sui-h-auto"
                  testId="action-container--next-button-verified"
                >
                  <Icon
                    name="check"
                    size="md"
                    className="sui-cursor-pointer sui-pt-1"
                  />
                </Button>
              ) : (
                <Button
                  color="primary"
                  mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                  onClick={() => verify()}
                  icon={isSubmitting ? '' : 'arrow-right'}
                  iconPosition={isSubmitting ? '' : 'right'}
                  isDisabled={!membershipNumberValue || isSubmitting}
                  testId="action-container--next-button"
                >
                  {isSubmitting ? <Loader type="spin" /> : 'Verify and Next'}
                </Button>
              )}
            </div>
          }
        >
          <div>
            <div
              data-testid="membership-logo-image"
              className="sui-flex sui-justify-center sui-mb-3"
            >
              <img className="sui-w-full" src={getLogoImage()} alt="TeamSnap" />
            </div>
            <h1
              data-testid="membership-header"
              className="sui-heading-md"
            >{`Verify your ${membershipNumberField['label']}`}</h1>
            <h4
              data-testid="membership-subheader"
              className="u-padTopMd"
            >{`Enter your ${membershipNumberField['label']} to continue`}</h4>

            {selectedHouseholdPerson && (
              <div className="u-spaceTopXl">
                <HouseholdMemberCard
                  iconDisabled={isSubmitting}
                  key={selectedHouseholdPerson?.id}
                  member={selectedHouseholdPerson}
                  memberRegistrations={[]}
                  selected={true}
                  onClickEdit={() => handleOnParticipantEdit()}
                />
              </div>
            )}

            <div className="u-spaceTopXl">
              <span data-testid="membership-field-label">
                {membershipNumberField['label']}
                {membershipNumberField['required'] ? (
                  <span className="sui-text-red-4">*</span>
                ) : null}
                <TooltipProvider>
                  <Tooltip
                    align="center"
                    content={membershipNumberField['registrant_info_text']}
                    side="right"
                  >
                    <Icon
                      name="info"
                      size="s"
                      className="sui-cursor-pointer sui-text-action-background u-spaceLeftXs"
                    />
                  </Tooltip>
                </TooltipProvider>
              </span>
              <AlphaNumericInput
                isDisabled={isSubmitting}
                value={membershipNumberValue}
                name="membershipNumber"
                onChange={(value: string) => setMembershipNumberValue(value)}
                placeholderText=""
                hasError={(error && error.length > 0) || false}
                testId="membership-field-input"
              />
              {error ? (
                <div
                  data-testid="membership-field-error"
                  className="u-spaceLeftXs u-spaceTopXs"
                >
                  <span className="sui-text-red-40">{error}</span>
                </div>
              ) : (
                <div
                  data-testid="membership-field-help-text"
                  className="u-spaceLeftXs u-spaceTopXs sui-text-gray-8"
                >
                  {membershipNumberField['registrant_help_text']}
                </div>
              )}
            </div>

            <div
              data-testid="membership-redirect-link"
              className="sui-flex sui-justify-center u-fontSizeLg u-spaceTopXl"
            >
              <LabelButton
                icon="open_in_new"
                iconPosition="right"
                labelText={metadata.get_membership_text}
                sentiment="default"
                size="large"
                variantType="tertiary"
                className="u-spaceTopXl"
                disabled={isSubmitting}
                onClick={() => openMembershipLink()}
              />
            </div>
          </div>
        </ActionContainer>
      </div>

      {isEditOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          type="edit"
          householdMember={selectedHouseholdPerson}
          canRemoveHouseholdMember={false}
        />
      )}
    </>
  );
};
